<template>
  <div>
    <d-modal
      :modal-id="'delete-message-confirm-booking' + booking.id"
      :hide="hideModalMessage"
      :display="displayDeleteMessage"
      title="delete_booking.title_toast"
      text="confirm_delete_booking"
      @on:confirm="$emit('on:confirm-delete')"
      @on:cancel="$emit('on:cancel-delete')"
    />
  </div>
</template>
<script>

export default {
  props: {
    displayDeleteMessage: {
      type: Boolean,
      default: false
    },
    hideModalMessage: {
      type: Boolean,
      default: false
    },
    booking: {
      type: Object,
      default: () => {
      },
    },
  }
}
</script>

<style>

</style>
